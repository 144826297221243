import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { login } from '../features/authSlice';
import { Role } from '../utils/enum';
import { fetchMyAccountData } from '../features/userSlices';

const schema = z.object({
  email: z.string().min(1, { message: 'البريد الالكتروني مطلوب' }),
  password: z.string().min(1, { message: 'كلمة المرور مطلوبة' }),
});

export default function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: zodResolver(schema) });
  const { status, error, isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const res = await dispatch(login(data));
      if (res.meta.requestStatus === "fulfilled") {
        const userRole = res.payload?.data?.user?.role; 
        localStorage.setItem('helper', true)
        localStorage.setItem('token' ,res.payload?.data.token)
        if (userRole === Role.USER) {
         
          return navigate('/');

        } else {
          return navigate('/admin');
        }

        
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  
  
  return (
    <>
      <Helmet>
        <title>تسجيل الدخول</title>
        <meta name="description" content="تسجيل الدخول إلى موقعنا" />
        <meta name="keywords" content="تسجيل الدخول, موقعنا" />
      </Helmet>
      <div className="login-page w-full">
      {isAuthenticated ? <div>
       <div className="flex justify-center items-center">
        <Link to="/" className="text-center text-white px-5 py-3 rounded-md bg-main">تم تسجيل الدخول بنجاح</Link>
      </div>

      </div> : <>
      <div className="w-[90%] md:w-[60%] lg:w-[40%] bg-white shadow p-5 rounded">
          <h2 className="text-center my-5">تسجيل الدخول</h2>
          <form className="flex flex-col w-full gap-y-5 text-gray-500" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label className="text-[13px]">البريد الإلكتروني أو الهاتف</label>
              <input
                type="text"
                {...register('email')}
                placeholder="البريد الالكتروني"
                className="h-[50px] w-full p-3 rounded-md"
              />
              {errors.email && <span className="text-red-500 text-[12px]">{errors.email.message}</span>}
            </div>

            <div>
              <div className="flex justify-between items-center mb-1">
                <label className="text-[13px]">كلمة المرور</label>
                <Link to={''} className="text-[13px]">فقدت كلمة المرور؟</Link>
              </div>
              <input
                type="password"
                {...register('password')}
                placeholder="كلمة المرور"
                className="h-[50px] w-full p-3 rounded-md"
              />
              {errors.password && <span className="text-red-500 text-[12px]">{errors.password.message}</span>}
            </div>

            {/* <div className="flex items-center justify-start">
              <input type="checkbox" {...register('remember')} className="h-[20px] w-[20px] mr-2" />
              <label className="text-[13px]">تذكرني</label>
            </div> */}
            {status === "failed" && error && <div className="text-red-500">{error}</div>}
            <button type="submit" className="bg-main h-[50px] p-3 rounded-md text-white">
              {status === "loading" ? 'تحميل...' : 'تسجيل الدخول'}
            </button>
          </form>
          <div className="flex justify-center items-center mt-3 text-[13px]">
            أنت مستخدم جديد؟ <Link to={'/register'}>سجل الآن</Link>
          </div>
        </div>
      </>}
      </div>
    </>
  );
}
