import React from 'react'
import { Link } from 'react-router-dom'
import ErrorIamge from "../assets/Oops404.svg"
export default function NotFound() {
  return (
    <div className='h-screen flex justify-center items-center'>
     <div className='flex flex-col'>
     <div>
      <img src={ErrorIamge} alt='Error' className='' />
     </div>
     <h2 className='text-2xl'>
         الصفحة غير موجودة
      </h2>
       <div className='my-3'>
         <Link to='/' className='mt-5 text-blue-500'>الرجوع إلى الصفحة الرئيسية</Link>
       </div>
     </div>
    </div>
  )
}
