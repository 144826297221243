import { Skeleton } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboardData } from '../features/dashboardSlice'
import { Lightbulb, OctagonAlert, UsersRound } from 'lucide-react'

export default function Count() {
  const {Totalusers,totalReports,totalInquiries ,status} = useSelector((state)=>state.dashboard)
 const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(fetchDashboardData())  
  },[dispatch])
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-5'>
      <div className='border rounded-md p-3 flex justify-between items-center relative'>
       
        <div>
        {status === 'loading' ? <Skeleton active paragraph={{rows:2,width:'100%'}} /> :  <div>
          <h3 className='text-main font-bold mb-2'>المستخدمين</h3>
          <p className='font-medium'>{Totalusers}</p>
        </div>}
        </div>

        <div className=''>
         <UsersRound className='text-main opacity-70' size={30} />
        </div>

      </div>
      <div className='border rounded-md p-3 flex justify-between items-center relative'>
        <div>
        {status === 'loading'? <Skeleton active paragraph={{rows:2,width:'100%'}} /> :  <div>
         <h3 className='text-main font-bold mb-2'>البلاغات</h3>
          <p className='font-medium'>{totalReports}</p>
        </div>}
        </div>

        <div>

        </div>
        <OctagonAlert className='text-main opacity-70' size={30} />
      </div>
      <div className='border rounded-md p-3 flex justify-between items-center relative'>
        <div>
        {status === 'loading'? <Skeleton active paragraph={{rows:2,width:'100%'}} /> :  <div>
         <h3 className='text-main font-bold mb-2'>الاستشارات</h3>
          <p className='font-medium'>{totalInquiries}</p>
        </div>}
        </div>

        <div>
        <Lightbulb className='text-main opacity-70' size={30} />
        </div>

     
      </div>

    </div>
  )
}
