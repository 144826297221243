import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import React from 'react'
import { Link } from 'react-router-dom';
import statusAR from '../utils/status';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { fetchConsultations } from '../features/consultingSlice';


export default function Consulting({data,isloading,fetchData,currentPage ,totalDocs}) {
  const dispatch = useDispatch();
    const columns=[
        { title: '#', dataIndex: 'id', key: 'id' },
        {  title: "مقدم الطلب", dataIndex: 'name', key: 'name' },
        {   title: "تاريخ الطلب", dataIndex: 'createdAt', key: 'createdAt' },
        {   title: "حالة الطلب", dataIndex: 'status', key: 'status' },
        { title: 'Action', dataIndex: 'action', key: 'action'}
    ]
    const dataSource = data?.map((el, index) => {
        return {
          key: el._id,
          id: (currentPage - 1) * 10 + index + 1,
          name: el.createdBy?.userName,
          createdAt: dayjs(el.createdAt).format("DD/MM/YYYY HH:mm:ss"),
          status:statusAR(el.statusOfInquiry),
          action: <Link to={`/admin/consulting/${el._id}`}>مشاهدة</Link>
        }

    })

  // handel pagination page
  const handlePageChange = async({page,limit}) => {
    await dispatch(fetchConsultations({page,limit}));
  };



    
  return <>
  <Helmet>
    <title>الأستشارات</title>
  </Helmet>
<div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={isloading}
        scroll={{x:500}}

      />
      <Pagination
          current={currentPage}
          total={totalDocs}
          pageSize={10}
          responsive={true}
          size={'small'}
          showTotal={(total, range) => `صفحة ${range[0]} - ${range[1]} من ${total}`}
          onShowSizeChange={(current, size) => handlePageChange({page: 1, limit: size})}
          onChange={(page,pageSize)=>handlePageChange({page, limit:pageSize})}
          showSizeChanger={true}
          className="mt-5"
      


        />

    </div>
  </>
}
