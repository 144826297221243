import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function SearchComponent({ SearchFunction, reload ,page }) {
  const [searchValue, setSearchValue] = useState('');
  const dispatch= useDispatch()

  const handleSearch =async  (event) => {
    setSearchValue(event.target.value);
    await SearchFunction({ page, search: searchValue })
  };

  return (
   <>
     {/* <form className='flex justify-center items-center w-full' >
      <div className='w-[70%] mr-3'>
        <input
          type="text"
          placeholder="Search..."
          value={searchValue}
          onChange={handleSearch}
          className='w-full'
        />
      </div>
      <div className='w-1/3 gap-4 flex justify-center items-center'>

        <button
          className='w-1/2 bg-gray-400 h-[50px] hover:bg-gray-300'
          onClick={() => reload({ page })}
        >
          Reload
        </button>
      </div>
    </form> */}
   </>
  );
}
