import React, { useEffect, useState } from 'react';
import { Button, Collapse, message, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { createConsultation, deleteConsultation, fetchMyConsult, updateMyConsult } from '../../features/consultingSlice';
import statusAr from '../../utils/status';
const { Panel } = Collapse;
const schema = z.object({
  text: z.string().min(1, 'النص مطلوب'),
});

export default function Consult() {
  const { consultations, status } = useSelector((state) => state.consulting);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingConsultation, setEditingConsultation] = useState(null); 

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      text: '',
    },
  });


  const openAddModal = () => {
    setEditingConsultation(null); 
    setIsModalOpen(true);
    reset(); 
  };

  const openEditModal = (consultation) => {
    setEditingConsultation(consultation); 
    setIsModalOpen(true);
    setValue('text', consultation.text); 
  };

  const onSubmit = async (data) => {

    if (editingConsultation) {
      const res = await dispatch(updateMyConsult({ id: editingConsultation._id, data }));
      if (res.meta.requestStatus === 'fulfilled') {
        toast.success('تم التعديل بنجاح');
        dispatch(fetchMyConsult())
        setIsModalOpen(false);
        reset();
      } else {
        message.error('حدث خطأ أثناء التعديل');
      }
    } else {
      const res = await dispatch(createConsultation({ data }));
      if (res.meta.requestStatus === 'fulfilled') {
        toast.success('تم الإرسال بنجاح');
        setIsModalOpen(false);
        reset();
      } else {
        message.error('حدث خطأ أثناء الإرسال');
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(fetchMyConsult());
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(deleteConsultation({ id }));
      if (res.meta.requestStatus === 'fulfilled') {
        message.success('تم الحذف بنجاح');
      }
    } catch (error) {
      message.error('حدث خطأ ما');
    }
  };

  return (
    <>
      <Helmet>
        <title>الاستشارات</title>
      </Helmet>

      <div className="container mx-auto mt-10">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="title">الاستشارات</h2>
          </div>
          <div>
            <Button type="primary" onClick={openAddModal}>
              إضافة إستشارة
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-10 gap-4">
          {status === 'loading' ? (
            <div>Loading...</div>
          ) : (
            consultations.map((consultation) => (
              <div key={consultation._id} className="border rounded-md p-3 h-auto">
                <h3 className="text-gray-600 font-bold mb-2">
                  الحالة : {statusAr(consultation.statusOfInquiry)}
                </h3>
                <p className="text-gray-500">
                  تاريخ الإستشارة : {dayjs(consultation.createdAt).format('DD/MM/YYYY')}
                </p>
                <Collapse>
                <Panel header={`عرض الإستشارة `} key="1">
                <p className="text-gray-500 w-[90%]">{consultation.text}</p>

                </Panel>

                </Collapse>

               
                <hr className="my-2" />
                <div className="flex justify-between items-center">
                  <Button
                    type="primary"
                    className="rounded-md mr-2"
                    onClick={() => openEditModal(consultation)}
                  >
                    تعديل
                  </Button>
                  <Button
                    type="danger"
                    className="rounded-md"
                    onClick={() => handleDelete(consultation._id)}
                  >
                    حذف
                  </Button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <Modal
        title={editingConsultation ? 'تعديل الإستشارة' : 'إضافة إستشارة'}
        open={isModalOpen}
        onOk={handleSubmit(onSubmit)}
        onCancel={handleCancel}
        okText={editingConsultation ? 'تعديل' : 'إضافة'}
        cancelText="إلغاء"
      >
        <form>
          <div className="my-5">
            <label>نص الإستشارة</label>
            <textarea
              {...register('text')}
              rows={6}
              placeholder="نص الاستشارة"
              className="w-full border border-gray-200 outline-none rounded-md my-2 p-2"
            />
            {errors.text && <span className="text-red-500">{errors.text.message}</span>}
          </div>
        </form>
      </Modal>
    </>
  );
}
