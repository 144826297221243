import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';

export default function RerportDetails() {
    const { id } = useParams();
    const { report, status, error } = useSelector((state) => state.report);
   const dispatch = useDispatch();



   if(status === "loading"){
    return <LoadingComponent />
}
  
  return <>
    <div className='container mx-auto my-10'>
    <h2 className='title'>تفاصيل البلاغ</h2>
     {error && <span className='text-red-500'>{error}</span>}
     {report && (
        <div>

        </div>
        )}
    </div>
  </>
}
