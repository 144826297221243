import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal} from 'antd';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { updateMyAccountData } from '../../features/userSlices';
import dayjs from 'dayjs';
import { RoleAr } from '../../utils/enum';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import UpdateMedia from '../../components/UpdateMedia';
import UpdatePassword from '../../components/UpdatePassword';


const schema = z.object({
  userName: z.string().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  address: z.string().optional(),
});

export default function Account() {
  const { user ,isError } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { register, handleSubmit, formState: { errors }} = useForm({
    resolver: zodResolver(schema),
    defaultValues: user
  });

  const onSubmit = async (data) => {
    const id = user?._id
   const res = await dispatch(updateMyAccountData({userId:id, data}));
    if (res.meta.requestStatus === "fulfilled") {
      toast.success('تم تحديث البيانات بنجاح');
      setIsModalOpen(false);
    } else {
        toast.error('حدث خطأ أثناء تحديث البيانات');
    }
    
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  return (
    <>
      <Helmet>
        <title>حسابي</title>
      </Helmet>
      <div className='w-full'>
        <h3 className='title'>الملف الشخصي</h3>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 my-5'>
          <div className='border rounded-md p-5 flex flex-col gap-2'>
            <h3 className='text-black font-bold my-3'>البيانات الشخصية </h3>
            <h4>الاسم : {user?.userName}</h4>
            <h4>البريد الالكتروني : {user?.email}</h4>
            <h4>تاريخ التسجيل : {dayjs(user?.createdAt).format('DD/MM/YYYY')}</h4>
            <h4>رقم الهاتف : {user?.phone}</h4>
            <h4>العنوان : {user?.address}</h4>
            <h4>الصلاحية : {RoleAr[user?.role]}</h4>

            <div>
            <Button type="primary" className='rounded-md w-fit' onClick={showModal}>
              تعديل معلوماتي
            </Button>

            <UpdatePassword userPassword={user?.password} />
            </div>
          </div>

          <div className='border rounded-md p-5 flex flex-col gap-2'>
          <h3 className='text-black font-bold my-3'> بيانات التواصل  </h3>
          {user?.socialMedia.map((platform, index) => (
            <div key={index} className='flex items-center gap-2'>
              <h4>   {platform.link} - {platform.platform}</h4>
              </div>
          ))}
        
           
              <UpdateMedia socialMedia={user?.socialMedia} userId={user?._id} />
           
          </div>


        </div>
      </div>
 
      <Modal title="تعديل البيانات" open={isModalOpen} onOk={handleSubmit(onSubmit)} onCancel={handleCancel} okText='تحديث' cancelText='الغاء'>
        <form >
        {isError && <div className="">
            <span className="text-red-500">  {isError} </span>
  
        </div>}
          <div>
            <label>اسم المستخدم</label>
            <input {...register('userName')} type='text' defaultValue={user?.userName} />
          </div>
          <div>
            <label>البريد الإلكتروني</label>
            <input {...register('email')} defaultValue={user?.email } type="email" />
          </div>
          <div>
            <label>رقم الهاتف</label>
            <input {...register('phone')} defaultValue={user?.phone } type='text' />
            {errors.phone && <span>{errors.phone.message}</span>}
          </div>

          <div>
            <label>العنوان</label>
            <input {...register('address')} type='text' defaultValue={user?.address} />
            {errors.address && <span>{errors.address.message}</span>}
          </div>    
        </form>
      </Modal>



    </>
  );
}
