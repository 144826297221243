import React from 'react'
import Account from './Account'

export default function Profile() {
  return (
    <div className='container mx-auto my-10 h-[60vh]'>
      <Account />
    </div>
  )
}
