import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
    status: "idle",
    error: null,
    consult: [],
    consultations:[],
    totalPages: 0,
    currentPage: null,
    totalDocs: null
}


export const fetchConsultations = createAsyncThunk("consultations/fetchConsultations", async ({ page=1,limit=10,search },{rejectWithValue}) => {
    try {
        const params = new URLSearchParams({ page ,limit  });
        if (search) {
            params.append('search', search);
        }
        const response = await api.get(`/inquiries?${params.toString()}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})


export const fetchConsultation = createAsyncThunk("consultations/fetchConsultation", async ({ id },{rejectWithValue}) => {
    try {
        const response = await api.get(`/inquiries/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const deleteConsultation = createAsyncThunk("consultations/deleteConsultation", async ({ id },{rejectWithValue}) => {
    try {
        await api.delete(`/inquiries/${id}`)
        return { id }
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const updateConsultationStatus = createAsyncThunk("consultations/updateConsultationStatus", async ({ id,status },{rejectWithValue}) => {
    try {
        const response = await api.put(`/inquiries/${status}/${id}/`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const createConsultation = createAsyncThunk("consultations/createConsultation", async ({ data },{rejectWithValue}) => {
    try {
        const response = await api.post(`/inquiries/`, data)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const updateConsultation = createAsyncThunk("consultations/updateConsultation", async ({ id, data },{rejectWithValue}) => {
    try {
        const response = await api.patch(`/inquiries/${id}`, {
            answerText:data.answerText,
            meetingDate:data.meetingDate

        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const updateMyConsult = createAsyncThunk("consultations/updateMyConsult", async ({ id, data },{rejectWithValue}) => {
    try {
        const response = await api.put(`/inquiries/${id}`, {
            text:data.text
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})



// fetch My Consult

export const fetchMyConsult= createAsyncThunk("consultations/fetchMyConsult", async (_,{rejectWithValue})=>{
    try {
        const response = await api.get(`/inquiries/my`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
} )


 
const consultationsSlice = createSlice({
    name: "consultations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
       .addCase(fetchConsultations.pending, (state) => {
            state.status = "loading";
        })
       .addCase(fetchConsultations.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.consultations = action.payload.data.inquiries.docs;
            state.totalPages = action.payload.data.inquiries.totalPages;
            state.currentPage = action.payload.data.inquiries.page;
            state.totalDocs = action.payload.data.inquiries.totalDocs;
        })
       .addCase(fetchConsultations.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        })

        builder
       .addCase(fetchConsultation.pending, (state) => {
         state.status = "loading";
       })
       .addCase(fetchConsultation.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.consult = action.payload.data.inquiry;
       })
       .addCase(fetchConsultation.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.payload;
       })

    //    update consult
    builder
       .addCase(updateConsultation.pending, (state) => {
         state.status = "loading";
       })
       .addCase(updateConsultation.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.consult = action.payload.data.inquiry;
       })
       .addCase(updateConsultation.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.payload;
       })

    //    get my consult
    builder
       .addCase(fetchMyConsult.pending, (state) => {
         state.status = "loading";
       })
       .addCase(fetchMyConsult.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.consultations = action.payload.data.inquiries;
       })
       .addCase(fetchMyConsult.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.payload;
       })

       //deleteConsultation
       builder
       .addCase(deleteConsultation.pending, (state) => {
         state.status = "loading";
       })
       .addCase(deleteConsultation.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.consultations = state.consultations.filter(consult => consult._id!== action.payload.id);
       })
       .addCase(deleteConsultation.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.payload;
       })

    //    upadte my updateMyConsult
    builder
       .addCase(updateMyConsult.pending, (state) => {
         state.status = "loading";
       })
       .addCase(updateMyConsult.fulfilled, (state, action) => {
         state.status = "succeeded";
         state.consult = action.payload.data.inquiry;
       })
       .addCase(updateMyConsult.rejected, (state, action) => {
         state.status = "failed";
         state.error = action.payload;
       })
  
       
    }
})


export default consultationsSlice.reducer;