import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { AddAnswerReport } from '../features/reportSlice';
import { toast } from 'react-toastify';

const schema = z.object({
  answerText: z.string().min(1, 'يجب ألا يكون الحقل فارغًا'),
})

export default function Answer({data, answerId, reload,typeRep}) {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });
  const dispatch = useDispatch();

  
  const reloadFun = async () => {
    if (typeof reload === 'function') {
      await reload();
    }
  };

  const type_of_report = typeRep === 'civil' ? 'civil-report' : 'criminal-report';

  const onSubmit = async (formData) => {
    try {
      const answerText = formData.answerText;
      const res = await dispatch(AddAnswerReport({ type: `${type_of_report}`, answerText, id: answerId }));

      if (res.meta.requestStatus === "fulfilled") {
        toast.success('تم اضافة التعليق بنجاح');
        reloadFun();
      } else if (res.meta.requestStatus === 'rejected') {
        toast.error('حدث خطأ أثناء التعليق');
      }

    } catch (error) {
      toast.error('حدث خطأ أثناء التقييم');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
   
        <textarea 
          {...register('answerText')} 
          defaultValue={data || ''}  
          className='w-full h-auto' 
          rows={8}
        />
        {errors.answerText && <p className="text-red-500">{errors.answerText.message}</p>}

        <Button htmlType='submit'>ارسل</Button>
      </form>
    </div>
  );
}
