// src/components/FileRenderer.jsx
import React from 'react';
import { FaFileImage, FaFileAlt, FaFileAudio, FaFileVideo } from 'react-icons/fa';
import { Image } from 'antd';
import PDF from '../assets/pdf.png'

const FileRenderer = ({ file }) => {
    const renderFileContent = (file) => {
        switch (file.fileType) {
            case "image":
                return (
                    <div key={file.fileUrl} className="flex items-center gap-2">
                        <Image width={100} height={100} src={`http://localhost:5000/`+file.fileUrl} alt={file.fileName} />
                    </div>
                );
            case "document":
                return (
                    <div key={file.fileUrl} className="flex items-center gap-2">
                      
                        <a href={`http://localhost:5000/`+file.fileUrl} target='_blank' rel='noopener noreferrer'>
                        <img src={PDF} alt='pdf' width={100} />
                        </a>
                      
                    
                    </div>
                );
            case "audio":
                return (
                    <div key={file.fileUrl} className="flex items-center gap-2">
                        <FaFileAudio style={{ marginRight: '8px' }} />
                        <audio controls>
                            <source src={`http://localhost:5000/`+file.fileUrl} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>
                    </div>
                );
            case "video":
                return (
                    <div key={file.fileUrl} className="flex items-center gap-2">
                        <FaFileVideo style={{ marginRight: '8px' }} />
                        <video width={320} height={240} controls>
                            <source src={`http://localhost:5000/`+file.fileUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                );
            default:
                return <p>Unsupported file type</p>;
        }
    };

    return <>{renderFileContent(file)}</>;
};

export default FileRenderer;
