import { RouterProvider } from "react-router-dom";
import Routers from "./routers/routers";
import { ConfigProvider } from "antd";
import { checkUserAuth } from "./features/authSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "./components/LoadingComponent";



function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, status,role } = useSelector((state) => state.auth);

  const token = localStorage.getItem('token'); 
  useEffect(() => {
    if (token && !isAuthenticated) {
      dispatch(checkUserAuth());
    }
  }, [isAuthenticated, dispatch, token]);

  if (status === 'loading') {
    return <LoadingComponent />;
  }




  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#302C89",
          borderRadius: 3,
          
        },
        components: {
          Menu: {
            itemSelectedColor: "#fff",
            itemActiveBgColor: "#302C89",
            itemHoverColor: "#ffffff",
            // itemSelectedBg: "#f1f1f1",
            itemColor: "#ffffff",

          },
          
          
        },
      }}
    >

      <RouterProvider router={Routers} />
    </ConfigProvider>
  );
}

export default App;
