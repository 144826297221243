import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { AddReviewReport } from '../features/reportSlice';
import { toast } from 'react-toastify';

const schema = z.object({
  reviewText: z.string().min(1, 'يجب ألا يكون الحقل فارغًا'),
})

export default function Review({data, reviewId, reload,typeRep}) {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });
  const dispatch = useDispatch();


  const reloadFun = async () => {
    if (typeof reload === 'function') {
      await reload();
    }
  };

  const type_of_report = typeRep === 'civil' ? 'civil-report' : 'criminal-report';
  const onSubmit = async (formData) => {
    try {
      const reviewText = formData.reviewText;
      const res = await dispatch(AddReviewReport({ type: `${type_of_report}`, reviewText, id: reviewId }));

      if (res.meta.requestStatus === "fulfilled") {
        toast.success('تم اضافة التعليق بنجاح');
        reloadFun(); 
      } else if (res.meta.requestStatus === 'rejected') {
        toast.error('حدث خطأ أثناء التعليق');
      }

    } catch (error) {
      toast.error('حدث خطأ أثناء التقييم');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
      
        <textarea 
          {...register('reviewText')} 
          defaultValue={data || ''} 
          className='w-full h-auto' 
          rows={8}
        />
        {errors.reviewText && <p className="text-red-500">{errors.reviewText.message}</p>}

        <Button htmlType='submit'>ارسل</Button>
      </form>
    </div>
  );
}
