import React from 'react'
import MyForm from '../../components/MyForm'

export default function AddReport() {
  return (
    <div>
      <MyForm />
    </div>
  )
}
