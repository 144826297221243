import  { createBrowserRouter } from "react-router-dom";
import UserLayout from "../layout/User";
import UserHome from "../pages/user/Home";
import AdminHome from "../pages/admin/Home";
import NotFound from "../components/NotFound";
import AdminLayout from "../layout/Admin"
import Users from "../pages/admin/Users";
import Employees from "../pages/admin/Employees";
import Login from "../auth/Login";
import ProtectedRoute from "../utils/ProtectedRoute";
import { Role } from "../utils/enum";
import Profile from "../pages/user/Profile";
import Civil from "../pages/admin/Civil";
import Criminal from "../pages/admin/Criminal";
import PageGuard from "../utils/PageGuard";
import Unauthorized from "../utils/Unauthorized";
import AllReports from "../pages/admin/AllReports";
import ReportDetails from "../components/ReportDetails";
import Account from "../pages/admin/Account";
import Register from "../auth/Register";
import AddReport from "../pages/user/AddReport";

import AboutUs from "../pages/user/AboutUs";
import Policies from "../pages/user/Policies";
import MyReport from "../pages/user/MyReport";
import Consult from "../pages/user/Consult";
import UserRerportDetails from "../pages/user/RerportDetails";
import ConsultingPage from "../pages/admin/ConsultingPage";
import ConsultingDetails from "../components/ConsultingDetails";

const Routers = createBrowserRouter([
 {path:'/',element:<UserLayout />,children:[
    {index:true,element:<UserHome />},
    {path:'/u/profile',element:<ProtectedRoute allowedRoles={[Role.USER]}><Profile /></ProtectedRoute>},
    {path:'login',element:<Login />},
    {path:'addReport',element:<AddReport />},
    {path:'about-us',element:<AboutUs />},
    {path:'/u/my-report',element:<ProtectedRoute allowedRoles={[Role.USER]}><MyReport /></ProtectedRoute>},
    {path:'/u/my-consult',element:<ProtectedRoute allowedRoles={[Role.USER]}><Consult /></ProtectedRoute>},
    {path:'/u/details/:id',element:<ProtectedRoute allowedRoles={[Role.USER]}><UserRerportDetails /></ProtectedRoute>},
    {path:'policies',element:<Policies />},
    {path:'register',element:<Register />},
    {path:'notfound',element:<NotFound />},
    
    {path:'*',element:<NotFound />},
 ]},
 {path :'/admin',element:<ProtectedRoute allowedRoles={[
   Role.ADMIN,Role.CRIMINAL_SPECIALIST,
   Role.CRIMINAL_LAWYER,Role.CIVIL_SPECIALIST,Role.CIVIL_LAWYER
   ]}><AdminLayout /> </ProtectedRoute>,children :[
    {index:true,element:<AdminHome />},
    {path:'civil',element:<PageGuard allowedRoles={[Role.ADMIN,Role.CIVIL_LAWYER,Role.CIVIL_SPECIALIST]}><Civil /></PageGuard>},
    {path:'all-reports',element:<PageGuard allowedRoles={[Role.ADMIN]}><AllReports /></PageGuard>},
    {path:'criminal',element:<PageGuard allowedRoles={[Role.ADMIN ,Role.CRIMINAL_LAWYER ,Role.CRIMINAL_SPECIALIST]}><Criminal /></PageGuard>},
    {path:'details/:id',element:<ReportDetails />},
    {path:'consulting',element:<PageGuard allowedRoles={[Role.ADMIN]}><ConsultingPage /></PageGuard>},
    {path:'consulting/:id',element:<PageGuard allowedRoles={[Role.ADMIN]}><ConsultingDetails /></PageGuard>},
    {path:'account',element:<Account />},
    {path:'users',element:<PageGuard allowedRoles={[Role.ADMIN]}><Users /></PageGuard>},
    {path:'employees',element:<PageGuard allowedRoles={[Role.ADMIN]}><Employees /></PageGuard>},
    {path:'*',element:<NotFound />},
    {path:'unauthorized',element:<Unauthorized />}
 ]},


])




export default Routers