import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchReports } from '../../features/reportSlice';
import ReportTable from '../../components/ReportTable';

export default function AllReports() {
    const { reports, currentPage, totalReports ,status } = useSelector((state) => state.report);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchReports({ currentPage }));
      }, [dispatch,currentPage]);
    
      const handleFetch = ({ page, limit }) => {
        dispatch(fetchReports({ page, limit }));
      };

      return (
        <>
          <h2 className="title" > كل البلاغات  </h2>
    
          <div className="my-5">
            <ReportTable
              data={reports}
              totalPages={totalReports}
              handleFetch={handleFetch}
              currentPage={currentPage}
              isloading={status==='loading'}
            />
          </div>
        </>
      );
    }
    