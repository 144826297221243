import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api"

const initialState = {
    status: "idle",
    error: null,
    reports: [],
    civilRep :[],
    criminalRep:[],
    report: null,
    totalPages: 0,
    currentPage: null,
    totalReports: null
}


export const fetchReports = createAsyncThunk("reports/fetchReports", async ({ page=1, search },{rejectWithValue}) => {
    try {
        const params = new URLSearchParams({ page });
        if (search) {
            params.append('search', search);
        }
        const response = await api.get(`/reports?${params.toString()}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const fetchReport = createAsyncThunk("reports/fetchById", async ({id,type=''}, {rejectWithValue}) => {
    try {
        const response = await api.get(`/reports/${type}/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

// get all  civil reports
export const fetchCivilReports =  createAsyncThunk("reports/fetchCivilReports", async ({ page=1, search,limit=10 },{rejectWithValue}) => {
    try {
        const params = new URLSearchParams({ page ,limit });
        if (search) {
            params.append('search', search);
        }
        const response = await api.get(`/reports/civil-report?${params.toString()}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

// get all criminal
export const fetchCriminalReports =  createAsyncThunk("reports/fetchCriminalReports", async ({ page=1, search,limit=10 },{rejectWithValue}) => {
    try {
        const params = new URLSearchParams({ page ,limit });
        if (search) {
            params.append('search', search);
        }
        const response = await api.get(`/reports/criminal-report?${params.toString()}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

// approved  to sipasific report
export const approveReport = createAsyncThunk("reports/approveReport", async ({ id },{rejectWithValue}) => {
    try {
        const response = await api.put(`/reports/approved/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

export const AddAnswerReport = createAsyncThunk(
  "reports/AddAnswerReport",
  async ({ type,answerText , id }, { rejectWithValue }) => {

    try {
      const response = await api.patch(`/reports/${type}/${id}`, {
        answerText
      });

console.log(response);

     
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'حدث خطأ أثناء المعالجة');
    }
  }
);

export const AddReviewReport = createAsyncThunk(
  "reports/AddReviewReport",
  async ({ type,reviewText , id }, { rejectWithValue }) => {

    try {
      const response = await api.put(`/reports/${type}/${id}`, {
        reviewText
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'حدث خطأ أثناء المعالجة');
    }
  }
);

// add new report  functions

export const AddNewReport = createAsyncThunk(
  "reports/AddNewReport",
  async (data, { rejectWithValue }) => {

    try {
      const response = await api.post(`/reports/`, data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'حدث خطأ أثناء المعالجة');
    }
  }
);

export const getMyreports = createAsyncThunk("reports/getMyreports", async (_,{rejectWithValue}) => {
  try {
      const response = await api.get(`/reports/my`)
      return response.data
  } catch (error) {
      return rejectWithValue(error.response.data.message)
  }
})
export const getMyreport = createAsyncThunk("reports/getMyreport", async (reportId,{rejectWithValue}) => {
  try {
      const response = await api.get(`/reports/my/${reportId}`)
      return response.data
  } catch (error) {
      return rejectWithValue(error.response.data.message)
  }
})


const reportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {},
    extraReducers: (builder)=>{
        builder
       .addCase(fetchReports.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(fetchReports.fulfilled, (state, action) => {
         state.status = "success"
         state.reports = action.payload.data.reports
         state.totalPages = action.payload.data.totalPages
         state.currentPage = action.payload.data.currentPage
         state.totalReports = action.payload.data.totalReports
       })
       .addCase(fetchReports.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });

    //    civil
    builder
       .addCase(fetchCivilReports.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(fetchCivilReports.fulfilled, (state, action) => {
         state.status = "success"
         state.civilRep = action.payload.data.reports
         state.totalPages = action.payload.data.totalPages
         state.currentPage = action.payload.data.currentPage
         state.totalReports = action.payload.data.totalReports
       })
       .addCase(fetchCivilReports.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });

    //    Criminal
    builder
       .addCase(fetchCriminalReports.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(fetchCriminalReports.fulfilled, (state, action) => {
         state.status = "success"
         state.criminalRep = action.payload.data.reports
         state.totalPages = action.payload.data.totalPages
         state.currentPage = action.payload.data.currentPage
         state.totalReports = action.payload.data.totalReports
       })
       .addCase(fetchCriminalReports.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });

      //  fetch single report
      builder
       .addCase(fetchReport.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(fetchReport.fulfilled, (state, action) => {
         state.status = "success"
         state.report = action.payload.data.report
       })
       .addCase(fetchReport.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });
      //  approve Report
      builder
       .addCase(approveReport.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(approveReport.fulfilled, (state, action) => {
         state.status = "success"
         state.reports = state.reports.map((report) => (report.id === action.payload.data.report.id? action.payload.data.report : report))
       })
       .addCase(approveReport.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });
      //  AddAnswerReport
      builder
       .addCase(AddAnswerReport.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(AddAnswerReport.fulfilled, (state, action) => {
         state.status = "success"
         state.report = action.payload.data.report
       })
       .addCase(AddAnswerReport.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       })
      //  AddReviewReport
      builder
       .addCase(AddReviewReport.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(AddReviewReport.fulfilled, (state, action) => {
         state.status = "success"
         state.report = action.payload.data.report
       })
       .addCase(AddReviewReport.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });
      //  addd new rtport 
      builder
       .addCase(AddNewReport.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(AddNewReport.fulfilled, (state, action) => {
         state.status = "success"
         state.reports = [...state.reports, action.payload.data.report]
       })
       .addCase(AddNewReport.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });

      //  get My reports
      builder
       .addCase(getMyreports.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(getMyreports.fulfilled, (state, action) => {
         state.status = "success"
         state.reports = action.payload.data
       })
       .addCase(getMyreports.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });

      //  get My report by  id
      builder
       .addCase(getMyreport.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(getMyreport.fulfilled, (state, action) => {
         state.status = "success"
         state.report = action.payload.data
       })
       .addCase(getMyreport.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });

    }
})


export default reportsSlice.reducer