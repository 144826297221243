import React  from 'react'

import { Table, Tooltip } from 'antd'
import socialMedia from '../utils/socialMedia'
import { useDispatch } from 'react-redux';
import { deleteUser } from '../features/userSlices';
import { toast } from 'react-toastify';
import { TbEdit, TbTrash } from 'react-icons/tb';
import dayjs from 'dayjs';
import { RoleAr } from '../utils/enum';


export default function UsersComponent({
  data,
  isloading,
  handleFetch,
  totalPages,
  currentPage
}) {
  const dispatch = useDispatch();
  
  const handleDelete = async (id) => {
 try {
  const res = await dispatch(deleteUser(id));
  if(res.meta.requestStatus === "fulfilled"){
   toast.success('تم حذف المستخدم')
    handleFetch(currentPage);
  }
  
 } catch (error) {
   toast.error('حدث خطأ ما')
  
 }
     

  };

const columns= [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'الاسم ', dataIndex: 'name', key: 'name' },
    { title: 'البريد الالكتروني', dataIndex: 'email', key: 'email' },
    { title: 'رقم الهاتف', dataIndex: 'phone', key: 'phone' },
    { title: 'التواصل ', dataIndex: 'media', key: 'media' },
    {title:'الصلاحية' , dataIndex:'role',key: 'role' },
    { title: 'تاريخ التسجيل', dataIndex: 'createdAt', key: 'createdAt' },
    { title: 'حذف', dataIndex: 'actions', key: 'actions' },
 
  
]

const page = currentPage;

    const dataSource = data?.map((user ,index) => ({
        key: user._id,
        id: (page - 1) * 10 + index + 1,
        name: user?.userName || '-',
        email: user.email ||  '-',
        phone: user.phone || '-',
       media: user.socialMedia ? <>
      <div className='flex gap-5'>
        {user.socialMedia?.map((media) => (
          <div key={media._id}>
          <Tooltip placement={"top"} title={media.link}>
            {socialMedia(media.platform, media.link)}
            </Tooltip>
          </div>
        ))}
      </div>
       </> : '-',
        role: RoleAr[user?.role] || '-',
        actions: <div className='flex items-center gap-4'>
          <button onClick={() => handleDelete(user._id)} className='w-fit border rounded bg-gray-100 p-1'><TbTrash size={17} className='text-gray-500 hover:text-red-500 duration-100' /></button>
        </div>,
        createdAt: user.createdAt? dayjs(user.createdAt).format('DD/MM/YYYY') : '-',
  
      
    }))






  
  return (<>
        <div>

  
     <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          total: totalPages,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `Total ${total} items`,
          defaultPageSize: 10,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (page, pageSize) => {
            handleFetch({ page, limit: pageSize });
          },
         
        }}
        loading={isloading}
        scroll={{x:500}}
      />
    </div>
    </div>
  </>

  )
}
