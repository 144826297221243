import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { approveReport, fetchReport } from '../features/reportSlice';
import { Role } from '../utils/enum';
import { Divider, Image, Table } from 'antd';
import statusFn from '../utils/status';
import dayjs from 'dayjs';
import { Check } from 'lucide-react';
import { toast } from 'react-toastify';
import { TbEdit } from 'react-icons/tb';
import Answer from '../form/Answer';
import Review from '../form/Review';
import FileRenderer from '../utils/FileRenderer';
import LoadingComponent from './LoadingComponent';



export default function ReportDetails() {
    const { id } = useParams();
    const { role } = useSelector((state) => state.auth);
    const { report, status, error } = useSelector((state) => state.report);
    const [edit, setEdit] = useState(false);
    const [editTow, setEditTow] = useState(false);
    const textHistoryContent = report?.textHistory;

    const dispatch = useDispatch();

    useEffect(() => {
        if (role === Role.CIVIL_LAWYER || role === Role.CIVIL_SPECIALIST) {
            dispatch(fetchReport({ id, type: 'civil-report' }));
        } else if (role === Role.CRIMINAL_LAWYER || role === Role.CRIMINAL_SPECIALIST) {
            dispatch(fetchReport({ id, type: 'criminal-report' }));
        } else if (role === Role.ADMIN) {
            dispatch(fetchReport({ id, type: '' }));
        }
    }, [dispatch, id, role]);

    const isLoading = status === "loading";
    
    const columns = [
        {
            title: "رقم القضية",
            dataIndex: "ref",
            key: "ref",
        },
        {
            title: "مقدم الطلب",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "نوع الطلب",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "الملفات المرفقة",
            dataIndex: "files",
            key: "files",
        },
        {
            title: "حالة الطلب",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "تاريخ الطلب",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "عرض",
            key: "edit",
            dataIndex: "edit",
        },
    ];

    const handleAproved = async (id) => {
        try {
            const res = await dispatch(approveReport({ id }));
            if (res.meta.requestStatus === "fulfilled") {
                toast.success('تمت الموافقة على التقرير بنجاح');
                handleRefresh();
            }
        } catch (error) {
            toast.error('حدث خطأ في المعالجة');
        }
    };

    const handleRefresh = async () => {
       await dispatch(fetchReport({ id, type: report?.typeOfReport === "civil" ? "civil-report" : "criminal-report" }));
        setEdit(false);
        setEditTow(false);
    };

    const dataSource = report ? [
        {
            key: report?._id,
            ref: report?.referenceNumber,
            name: report?.createdBy?.userName,
            type: report?.typeOfReport === "civil" ? "مدني" : "جنائي",
            files: report?.files?.length > 0 ? report?.files?.length : "لا ملفات مرفقة",
            status: statusFn(report?.statusOfReport),
            date: dayjs(report?.createdAt).format("DD/MM/YYYY"),
            edit: role !== Role.ADMIN ? '-' : (
                report?.statusOfReport === "answered" && report.statusOfReport === "approved" ?
                    <span>تمت الموافقة</span> :
                    <button
                        disabled={report.statusOfReport === "approved"}
                        className={`flex items-center gap-x-2 border rounded-md ${report.statusOfReport === "approved" && 'text-gray-300'}`}
                        onClick={() => handleAproved(report?._id)}
                    >
                        <Check size={20} className={`${report.statusOfReport === "approved" ? 'text-gray-300' : 'text-green-500'}`} />
                        <span className='ml-2'>الموافقة</span>
                    </button>
            ),
        },
    ] : [];



     
    return (
        <div className='w-full'>
            <h2 className='title'>تفاصيل التقرير </h2>
            {error && <span className='text-red-500'>{error}</span>}
            <div className='my-5'>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    pagination={false}
                    scroll={{ x: 1000 }}
                    rowKey={(record) => record.key}
                />
            
                <div className='w-full my-5'>
                    <h3 className='text-md font-bold'>الملفات المرفقة:</h3>
                    <div className='flex flex-wrap gap-x-2 my-2'>
                    {report?.files?.map((file, index) => (
                            <FileRenderer key={index} file={file} /> 
                        ))}
                    </div>
                </div>
                <div className='w-full my-5'>
                    <div> 
                    <h3 className='text-md font-bold'>الوصف :</h3>
                    <div className='text-md flex items-center justify-start gap-x-2 w-full p-2  mb-4'>
                        <p  dangerouslySetInnerHTML={{ __html: report?.text ? report?.text : 'لا يوجد وصف' }}/>
                    </div>
                    </div>

                         {/* عرض النص المحفوظ بدون عرض الـ HTML */}
            {/* {textHistoryContent && (
                <div className='my-5 w-full'>
                <h2>النص المحفوظ:</h2>
                <p className='w-full'> 
                {textHistoryContent}
                </p>
        </div>
      )} */}


                    <div>
                        <div className='text-md flex items-center justify-between gap-x-2 bg-main w-full p-2 pl-3 text-white rounded-md mb-4'>
                            <div className='flex items-center gap-3'>
                                <h3 className=''>تعليق الموظف</h3>:
                                <h3>{report?.reviewedBy ? report?.reviewedBy.userName : 'لا يوجد مجيب'}</h3>

                            </div>
                            <button onClick={() => setEdit(!edit)}>
                                <TbEdit size={20} />
                            </button>
                        </div>

                        {edit ? (
                            <div className='h-auto'>
                                <Review typeRep={report?.typeOfReport} data={report?.reviewText} reviewId={report._id} reload={() => handleRefresh()} />

                            
                            </div>
                        ) : (
                            <p className='leading-8' >{report?.reviewText ? report?.reviewText : 'لا يوجد تعليق'}</p>
                        )}
                    </div>
                    <Divider />
                    <div className='my-8'>
                        <div className='text-md flex items-center justify-between gap-x-2 bg-main w-full p-2 pl-3 text-white rounded-md mb-4'>
                            <div className='flex items-center gap-3'>
                                <h3>تعليق المحامي</h3>:
                                <h3>{report?.answeredBy ? report?.answeredBy.userName : 'لا يوجد مجيب'}</h3>
                            </div>
                            <div>
                              {role === Role.ADMIN || role === Role.CRIMINAL_LAWYER || role === Role.CIVIL_LAWYER ? <>
                                <button onClick={() => setEditTow(!editTow)}>
                                    <TbEdit size={20} />
                                </button>
                              </> : '' }
                            </div>
                            

                        </div>
                          {role === Role.ADMIN || role === Role.CRIMINAL_LAWYER || role === Role.CIVIL_LAWYER ? <>
                            {editTow ? (
                            <div className='h-auto'>
                            <Answer typeRep={report?.typeOfReport} data={report?.answerText} answerId={report._id} reload={() => handleRefresh()} />
                            </div>
                        ) : (
                            <p className='leading-8'>{report?.answerText ? report?.answerText : 'لا يوجد تعليق'}</p>
                        )}
                        </> : ''
                          }
                        
                          {/* <p className='leading-8'>{report?.answerText ? report?.answerText : 'لا يوجد تعليق'}</p> */}

                     
                    </div>
                </div>
            </div>
        </div>
    );
}
