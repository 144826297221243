import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMyreports } from '../../features/reportSlice';
import ReportTable from '../../components/ReportTable';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import statusAR from '../../utils/status';
export default function MyReport() {
  const { reports,status } = useSelector((state) => state.report);

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getMyreports())
  },[dispatch])

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "نوع الطلب",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "حالة الطلب",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "تاريخ الطلب",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "عرض",
      key: "edit",
      dataIndex:"edit"
    }
  ];

  

  const dataSource = reports?.map((el, index) => {
    return {
      key: el._id,
      id: index + 1,
      type: el.typeOfReport === "civil" ? "مدني" : "جنائي",
      // files: el.files.length > 0 ? el.files.length : "لا ملفات مرفقة",
      status: statusAR(el.statusOfReport),
      date: dayjs(el.createdAt).format("DD/MM/YYYY"),
      edit: <Link to={`/u/details/${el._id}`} className="text-[13px]">عرض التفاصيل</Link>,
    };
  }) 

  return (
    <div className='container mx-auto my-10'>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={status === 'loading'}
        pagination={false}
      />
    </div>
  );
}