import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logout, logoutUser } from '../features/authSlice'
import { fetchMyAccountData } from '../features/userSlices';
import { Avatar, Drawer, Dropdown } from 'antd';
import { AlignJustify } from 'lucide-react';
import { Role } from '../utils/enum';
import LoadingComponent from '../components/LoadingComponent';

export default function Navbar() {
  const { user } = useSelector((state) => state.user);
  const { isAuthenticated, role ,status } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const navigate = useNavigate()
const helper = localStorage.getItem('helper')
const token = localStorage.getItem('token')


const [open, setOpen] = useState(false);
const showDrawer = () => {
  setOpen(true);
};
const onClose = () => {
  setOpen(false);
};

const firstLetter = user ? user?.userName.charAt(0).toUpperCase() : "";


  useEffect(() => {
    
    if(isAuthenticated) {
      dispatch(fetchMyAccountData());
    }
  
  }, [dispatch ,role,isAuthenticated]);

  const handleLogOut = ()=>{
    dispatch(logoutUser())
    navigate('/login')
    localStorage.removeItem('token')
    localStorage.removeItem('helper')
  }

  const nav = [
    { label: "الرئيسية", to: "/" },
    { label: "من نحن", to: "/about-us" },
    { label: "السياسات", to: "/policies" },
    { label: "انشاء توكيل", to: "/addReport" },
  ];

  const items = [
    { key: "1", label: <Link to={role === Role.USER ? "/u/profile": "/admin"}>حسابي</Link> },
   
    {
      key: "4",
      label: <Link to={"/u/my-report"}> القضايا</Link>,
    },
    { key: "5", label: <Link to={"/u/my-consult"}>الأستشارات</Link> },
    { key: "2", label: <button onClick={handleLogOut}>تسجيل الخروج</button> },
  ];

  // loading status
  if(status === 'loading') return <div><LoadingComponent/></div>
  return (
    <header className="bg-white  overflow-hidden px-5 py-4">
    <nav className="flex justify-between items-center container mx-auto">
      <div>
        <Link to='/'>
          الشرقاوي
        </Link>
      </div>
      <div className="hidden justify-center sm:hidden md:flex">
        {nav.map((item) => (
          <Link key={item.label} to={item.to} className="px-2" dir="rtl">
            {item.label}
          </Link>
        ))}
      </div>
      <div className="flex justify-end gap-x-2">
        {isAuthenticated ? (
          role  ? (
            <>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "#fde3cf",
                    color: "#f56a00",
                    cursor: "pointer",
                  }}
                >
                  {firstLetter}
                </Avatar>
              </Dropdown>
            </>
          ) : null
        ) : (
          <Link to="/login" className="px-2">
            تسجيل الدخول
          </Link>
        )}

        <div className="bg-main  md:hidden  h-8 w-8 flex justify-center items-center rounded-lg cursor-pointer" >
        <AlignJustify className="text-white" size={20} onClick={showDrawer} />
        </div>
      </div>
 
    </nav>

    <Drawer title="القائمة" onClose={onClose} open={open} width={350}>
     <div className="flex flex-col gap-y-3">
     {nav.map((item) => (
          <Link key={item.label} to={item.to} className="px-2 hover:text-main" dir="rtl">
            {item.label}
          </Link>
        ))}
     </div>
    </Drawer>
  </header>
  )
}