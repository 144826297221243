import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "react-toastify";
import { userUpdatePassword } from "../features/userSlices";
import { TbEye, TbEyeClosed } from "react-icons/tb";

const schema = z.object({
  oldPassword: z
    .string()
    .min(8, "كلمة المرور الحالية يجب أن تكون على الأقل 8  أحرف"),
  newPassword: z
    .string()
    .min(8, "كلمة المرور الجديدة يجب أن تكون على الأقل 8  أحرف"),
});
export default function UpdatePassword() {
    const [show,setShow] = useState('password')

  const handleChange = () => {
    setShow(show === 'password'? 'text' : 'password')  
  }



  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const showModal = () => {
    setVisible(true);
  };

  const onSubmit = async (data) => {
    try {
      const res = await dispatch(
        userUpdatePassword({
          newPassword: data.newPassword,
          oldPassword: data.oldPassword,
        })
      );

      if (res.meta.requestStatus === "fulfilled") {
        toast.success("تم تغيير كلمة المرور بنجاح");
        setVisible(false);
        reset();
      } else if (res.meta.requestStatus === "rejected") {
        toast.error(res.payload || "حدث خطأ أثناء تغيير كلمة المرور");
      }
    } catch (error) {
      toast.error("حدث خطأ ما");
    } finally {
      setVisible(false);
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal}>تغيير كلمة المرور</Button>
      <Modal
        title="تغيير كلمة المرور"
        open={visible}
        footer={false}
        onCancel={handleCancel}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div>
            <label>كلمة المرور الحالية:</label>
            
           <div className="relative">
           <span onClick={handleChange} className="bg-gray-200 absolute h-[80%] top-1 bottom-1 rounded-lg flex justify-center items-center left-1 w-10">
            {show === 'password'? <TbEye  className="duration-200" /> : <TbEyeClosed className="duration-200"/>}
          </span>
           <input {...register("oldPassword")} type={show}  />
           </div>
            {errors.oldPassword && <p>{errors.oldPassword.message}</p>}
          </div>

          <div>
            <label>كلمة المرور الجديدة:</label>
            <input {...register("newPassword")}  type={show}/>
            {errors.newPassword && <p>{errors.newPassword.message}</p>}
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className="w-fit"
            disabled={Boolean(errors.oldPassword || errors.newPassword)}
          >
            تغيير
          </Button>
        </form>
      </Modal>
    </>
  );
}
