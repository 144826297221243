import React, { useEffect } from "react";
import ReportTable from "../../components/ReportTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchCivilReports } from "../../features/reportSlice";

export default function Civil() {
  const { civilRep, currentPage, totalReports ,status} = useSelector((state) => state.report);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchCivilReports({ currentPage }));
  }, [dispatch,currentPage]);

  const handleFetch = ({ page, limit }) => {
    dispatch(fetchCivilReports({ page, limit }));
  };

  return (
    <>
      <h2 className="title">البلاغات المدنية </h2>

      <div className="my-5">
        <ReportTable
          data={civilRep}
          totalPages={totalReports}
          handleFetch={handleFetch}
          currentPage={currentPage}
          isloading={status==='loading'}
        />
      </div>
    </>
  );
}
