export const Role = {
    USER: 10,
    ADMIN: 20,
    CRIMINAL_SPECIALIST: 30,
    CRIMINAL_LAWYER: 40,
    CIVIL_SPECIALIST: 50,
    CIVIL_LAWYER: 60,
    INQUIRY_RESPONDER: 70
  };
  

  export const RoleAr = {
    [Role.USER]: 'عضو',
    [Role.ADMIN]: 'مدير',
    [Role.CRIMINAL_SPECIALIST]: 'متخصص جنائي',
    [Role.CRIMINAL_LAWYER]: 'محامي جنائي',
    [Role.CIVIL_SPECIALIST]: 'متخصص مدني',
    [Role.CIVIL_LAWYER]: 'محامي مدني',
    [Role.INQUIRY_RESPONDER]: 'مسؤول الأستشارات'
  }