import React from 'react'
import { Helmet } from 'react-helmet'
import Count from '../../components/Count'
import MyFrom from '../../components/MyForm'

export default function Home() {
  return (
  <>
  <Helmet>
    <title>Elshargawi</title>
    {/* <meta name="description" content="Welcome to Elshargawi, a digital platform for connecting, sharing, and organizing students' digital resources." /> */}
    <meta property="og:title" content="Elshargawi" />
    {/* <meta property="og:description" content="Welcome to Elshargawi, a digital platform for connecting, sharing, and organizing students' digital resources." /> */}
    <meta property="og:type" content="website" />
    {/* <meta property="og:url" content="https://elshargawi.com" />
    <meta property="og:image" content="https://elshargawi.com/logo.png" /> */}
    <meta property="og:site_name" content="Elshargawi" />
    <meta name="twitter:card" content="summary_large_image" />
  </Helmet>
      <div >
      <h3>
        Welcome to Elshargawi !
       
      </h3>

      <div>
        <Count  />
      </div>
 
    </div>
  </>
  )
}
