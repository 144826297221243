import React from 'react'
import { Helmet } from 'react-helmet'
import { TbArrowLeft } from 'react-icons/tb'
import Steps from '../../components/Steps'
import Faq from '../../components/Faq'


export default function Home() {
  return <>
  <Helmet>
    <title> الرئيسية</title>
  </Helmet>
   <div className='text-center container mx-auto h-[65dvh] flex flex-col justify-evenly items-center  rounded-md p-5 bg-gray-50 mt-10 shadow-sm relative'>
   <div className='w-full absolute  border-gray-300 border-2 left-0 right-0 top-0 shep h-full rounded-md animate-pulse duration-150'></div>
   
   <div>
   <h1 className='font-bold text-[30px] mb-5 text-main'>شركة الشرقاوي للحلول المتكاملة</h1>
    <p className='w-full md:w-1/2 mx-auto text-gray-500 leading-8'>
      شركة الشرقاوي للحلول المتكاملة هي شركة متخصصة في تنمية النظمة المتكاملة للحلول والتقنيات المتكاملة. نشركتنا تقدم خدمات إدارية وتقنية متكاملة لتقويم النظمة المتكاملة، والتعامل مع المستخدمين، والتحكم في التواصل المتكامل
    </p>
   </div>
    <div className='flex items-center justify-center  gap-x-2'>
    
       
      <button className='flex items-center justify-center gap-2 text-main p-2 border rounded-md'>
      
      الموقع الرسمي 
      <TbArrowLeft className='animate-pulse' />
       </button>
    </div>

   </div>

{/* <div className='container mx-auto h-[65dvh]'>
   <Steps />
</div> */}
<div className='container mx-auto h-[65dvh]'>
   <Faq />
</div>
  </>
}
