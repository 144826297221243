import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice"
import reportReducer from "./features/reportSlice"
import userReducer from "./features/userSlices"
import dashboardReducer from "./features/dashboardSlice"
import consultingReducer from "./features/consultingSlice"
export default configureStore({
    reducer: {
        auth:authReducer,
        report:reportReducer,
        user:userReducer,
        dashboard:dashboardReducer,
        // consultations reducer
        consulting:consultingReducer

    },
});