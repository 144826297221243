
import React from 'react';
import { useSelector } from 'react-redux';
import Unauthorized from './Unauthorized';

const PageGuard = ({ children, allowedRoles }) => {
    const {role } = useSelector((state) => state.auth);

    // تحقق من الصلاحيات
    if (!allowedRoles.includes(role)) {
        return  <>
           <Unauthorized />
        </> ;
    }

    return children;
};

export default PageGuard;
