import React from 'react'
import { TbLoadBalancer, TbLoader2 } from 'react-icons/tb'

export default function LoadingComponent() {
  return <>
    <div className='absolute h-screen w-full bg-main text-white flex justify-center items-center'>
            <div className='flex flex-col items-center gap-2'>
            <TbLoader2 className='animate-spin' size={20}/>
                <span className='text-sm '>جاري التحميل ..</span>
            </div>
    </div>
  </>
}
