import { Collapse } from 'antd';
import React from 'react'

export default function Faq() {
  const text = `
و ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص
`;
const items = [
  {
    key: '1',
    label: 'This is panel header 1',
    children: <p>{text}</p>,
  },
  {
    key: '2',
    label: 'This is panel header 2',
    children: <p>{text}</p>,
  },
  {
    key: '3',
    label: 'This is panel header 3',
    children: <p>{text}</p>,
  },
];
  return (
    <div className='container mx-auto my-10'>
       <h2 className='text-center title mb-2'>الأسئلة الشائعة</h2>

       <div>
       <Collapse 
       accordion
        items={items}
        expandIconPosition='end'
         />
       </div>
    </div>
  )
}
