import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Consulting from '../../components/Consulting'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConsultations } from '../../features/consultingSlice'

export default function ConsultingPage() {
  const {consultations,status,currentPage,error ,totalDocs} = useSelector((state)=>state.consulting)
 const dispatch =useDispatch()
  useEffect(()=>{
    dispatch(fetchConsultations({currentPage}))
  },[dispatch]) 


  return <>
    <Helmet>
      <title>الاستشارات</title>
    </Helmet>
    <div>
      <h3 className='title mb-5'>
        الأستشارات
      </h3>
      {error && <h3 className='text-red-500'>{error}</h3>}


      <div>
        <Consulting 
        currentPage={currentPage}
         data={consultations}
          isloading={status=== 'loading'}
          totalDocs={totalDocs}

            />
      </div>
    </div>
  </>
}
