import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyAccountData } from "../features/userSlices";
import { logoutUser } from "../features/authSlice";
import { Avatar, Button, Dropdown } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LuAlignJustify } from "react-icons/lu";

export default function AdminHeader({ toggleCollapsed }) {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMyAccountData());
  }, [dispatch]);

  const handleLogOut = () => {
    dispatch(logoutUser());
    navigate('/login');
    localStorage.removeItem('token');
    localStorage.removeItem('helper');
  };

  const items = [
    {
      label: <Link to={"/admin/account"}>الملف الشخصي</Link>,
      key: "0",
    },
    {
      label: <button onClick={handleLogOut}>تسجيل الخروج</button>,
      key: "1",
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center">
        <div>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{ marginBottom: 16 }}
          >
            <LuAlignJustify />
          </Button>
        </div>
        <div className="flex items-center  ">
          <div className="flex items-center gap-2">
            <div  className="hidden md:block"><h5>{user?.userName}</h5></div>
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              className="cursor-pointer"
            >
              <Avatar
                style={{
                  verticalAlign: "middle",
                }}
                size="default"
               
              >
               
               {user?.userName.slice(0, 2)}
               
              </Avatar>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}
