import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TbLogin, TbReload } from "react-icons/tb";
import { userRegister } from "../features/authSlice";
import { Link } from "react-router-dom";

const schema = z.object({
  email: z.string().min(1, { message: "البريد الالكتروني مطلوب" }),
  password: z.string().min(1, { message: "كلمة المرور مطلوبة" }),
  userName: z.string().min(1, { message: "الاسم مطلوب" }),
  phone: z.string().min(1, { message: "رقم التليفون مطلوب" }),
});

export default function Register() {
  const { status, error, role } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const res = await dispatch(userRegister(data));
    if (res.meta.requestStatus === "fulfilled") {
      toast.success("تم التسجيل بنجاح");
      window.location.href = "/login";
    }
  };
  return (
    <>
      <Helmet>
        <title> انشاء حساب </title>
      </Helmet>
      <div>
        <div className="login-page w-full">
          <div className="w-[90%] md:w-[60%] lg:w-[40%] bg-white shadow p-5 rounded">
            <h2 className="text-center my-5"> انشاء حساب </h2>
            {error && (
              <div>
                <h3 className="my-2 text-red-500"> {error}</h3>
              </div>
            )}

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col w-full gap-y-5 text-gray-500"
            >
              <div>
                <label>الاسم</label>
                <input {...register("userName")} placeholder="الاسم" />
                {errors.useName && <p className="text-red-500 text-[12px]">{errors.useName.message}</p>}
              </div>

              <div>
                <label>رقم الهاتف</label>
                <input {...register("phone")} placeholder="رقم الهاتف" />
                {errors.phone && <p className="text-red-500 text-[12px]">{errors.phone.message}</p>}
              </div>
              <div>
                <label>البريد الالكتروني</label>
                <input {...register("email")} placeholder="البريد الالكتروني" />
                {errors.email && <p className="text-red-500 text-[12px]">{errors.email.message}</p>}
              </div>

              <div>
                <label>كلمة المرور</label>
                <input
                  {...register("password")}
                  type="password"
                  placeholder="كلمة المرور"
                />
                {errors.password && <p className="text-red-500 text-[12px]">{errors.password.message}</p>}
              </div>

              <button type="submit" className="bg-main h-[50px] p-3 rounded-md text-white">
              انشاء حساب
                {status === "pending" && <TbReload size="20" />}
                {status === "fulfilled" && <TbLogin size="20" />}
              </button>

             
              <div className="text-center text-gray-500 text-sm">
                <p>
                  لديك حساب
                 
                 ؟ <Link to="/login">تسجيل الدخول</Link> 
                </p>
              </div>
            </form>

          </div>
        </div>
      </div>
    </>
  );
}
