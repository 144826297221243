import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { TbLogin, TbReload } from "react-icons/tb";
import { userRegister } from "../../features/authSlice";
import { Select } from "antd";
import { Role } from "../../utils/enum";
const { Option } = Select;

const schema = z.object({
  email: z.string().min(1, { message: "البريد الالكتروني مطلوب" }),
  password: z.string().min(1, { message: "كلمة المرور مطلوبة" }),
  userName: z.string().min(1, { message: "الاسم مطلوب" }),
  phone: z.string().min(1, { message: "رقم التليفون مطلوب" }),
  role:z.number().min(1, { message: "الصلاحيات مطلوبة" })
});

export default function NewUser() {
    const { status, error } = useSelector((state) => state.auth);

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm({ resolver: zodResolver(schema) ,
      defaultValues: {
        role: "user",
        email: "",
        password: "",
        phone: "",
        userName: "",
      } 
     });
    const dispatch = useDispatch();
  
    const onSubmit = async (data) => {
      console.log(data);
      
      const res = await dispatch(userRegister(data));
      console.log(res);
      
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("تم التسجيل بنجاح");
      }else{
        toast.error(res.payload);
        
      }
    };
    return (
        <>

          <div>
            <div className=" w-full">
              <div className="w-full bg-white  p-5 rounded">
                <h2 className="text-center my-5"> انشاء حساب </h2>
                {error && (
                  <div>
                    <h3 className="my-2 text-red-500"> {error}</h3>
                  </div>
                )}
    
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col w-full gap-y-5 text-gray-500"
                >
                  <div>
                    <label>الاسم</label>
                    <input {...register("userName")} placeholder="الاسم" />
                    {errors.userName && <p className="text-red-500 text-[12px]">{errors.userName.message}</p>}
                  </div>
    
                  <div>
                    <label>رقم الهاتف</label>
                    <input {...register("phone")} placeholder="رقم الهاتف" />
                    {errors.phone && <p className="text-red-500 text-[12px]">{errors.phone.message}</p>}
                  </div>
                  <div>
                    <label>البريد الالكتروني</label>
                    <input {...register("email")} placeholder="البريد الالكتروني" />
                    {errors.email && <p className="text-red-500 text-[12px]">{errors.email.message}</p>}
                  </div>
                  <div>
                    <label>الصلاحيات</label>
                    <Select {...register("role")}
                      onChange={(value) => setValue("role", value)}
                      placeholder="اختر الصلاحية"
                     className="h-[50px] w-full p-3 rounded-md">
                        <Option value={Role.ADMIN}>مدير</Option>
                        <Option value={Role.CIVIL_LAWYER}>محامي مدني</Option>
                        <Option value={Role.CIVIL_SPECIALIST}>متخصص مدني</Option>
                        <Option value={Role.CRIMINAL_LAWYER}>محامي جنائي</Option>
                        <Option value={Role.CRIMINAL_SPECIALIST}>متخصص جنائي</Option>
                        <Option value={Role.USER}>مستخدم</Option>
                        
                    </Select>
                    {errors.role && <p className="text-red-500 text-[12px]">{errors.role.message}</p>}
                  </div>
    
                  <div>
                    <label>كلمة المرور</label>
                    <input
                      {...register("password")}
                      type="password"
                      placeholder="كلمة المرور"
                    />
                    {errors.password && <p className="text-red-500 text-[12px]">{errors.password.message}</p>}
                  </div>
    
                  <button type="submit" className="bg-main h-[50px] p-3 rounded-md text-white">
                  انشاء حساب
                    {status === "loading" && <TbReload size="20" />}
                    {status === "fulfilled" && <TbLogin size="20" />}
                  </button>

                </form>
    
              </div>
            </div>
          </div>
        </>
      );
    }