import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConsultation, updateConsultation } from '../features/consultingSlice'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, DatePicker, Divider } from 'antd'
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "react-toastify";
import dayjs from 'dayjs';  

const schema = z.object({
  answerText: z.string().min(1, 'يجب ألا يكون الحقل فارغًا'),
});

export default function ConsultingDetails() {
    const {id} = useParams();
    const {consult, error} = useSelector((state) => state.consulting);
    const [edit, setEdit] = useState(false);
    const [editMeeting, setEditMeeting] = useState(false); // للتحكم في إظهار وإخفاء حقل الاجتماع
    const dispatch = useDispatch();
    
    const {register, handleSubmit, control, setValue, formState: { errors }} = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            answerText: '',
        },
    });

    useEffect(() => {
        if (consult) {
            setValue('answerText', consult.answerText || '');
        }
    }, [consult, setValue]);

    const handleEdit = () => {
        setEdit(!edit);
    };

    // دالة لحفظ الرد
    const onSubmit = async (data) => {
        const res = await dispatch(updateConsultation({ id, data }));
        if (res.meta.requestStatus === "fulfilled") {
            toast.success('تم التعديل بنجاح');
            setEdit(false);
        } else {
            toast.error('حدث خطأ ما');
        }
    };

    // دالة لحفظ أو تعديل تاريخ الاجتماع
    const onSubmitMeetingDate = async (date) => {
        const formattedDate = date ? dayjs(date).toDate() : null;
        const res = await dispatch(updateConsultation({ id, data: {answerText :consult?.answerText, meetingDate: formattedDate } }));
        if (res.meta.requestStatus === "fulfilled") {
            toast.success('تم حفظ تاريخ الاجتماع بنجاح');
            setEditMeeting(false);
        } else {
            toast.error('حدث خطأ ما');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await dispatch(fetchConsultation({ id }));
            if (result.meta.requestStatus !== "fulfilled") {
                toast.error('حدث خطأ أثناء جلب الاستشارة');
            }
        };
        fetchData();
    }, [dispatch, id]);

    return (
        <>
            <Helmet>
                <title>
                    {consult ? `استشارة - ${consult?.createdBy?.userName}` : "تفاصيل الأستشارة"}
                </title>
            </Helmet>
            <div>
                <h3 className='title mb-3'>تفاصيل الأستشارة</h3>
                {error && <span className='text-red-500'>{error}</span>}

                <div>
                    {consult && (
                        <>
                            <div>
                                <p>اسم المستخدم: {consult?.createdBy?.userName}</p>
                                {consult?.meetingDate && (
                                    <p className='my-2'>تاريخ الاجتماع: {dayjs(consult.meetingDate).format("DD-MM-YYYY - الساعة h:mm A")}</p>
                                )}
                                <p className='my-3'>
                                    <span className='font-medium mb-1 block'> التفاصيل : </span>
                                    {consult?.text}
                                </p>
                            </div>
                            <Divider />
                            {/* admin */}
                            <div className='my-5'>
                                <div className='flex justify-between items-center '>
                                    <h2 className='title'>الادارة</h2>
                                    <Button type='dashed' onClick={handleEdit}>تعديل او اضافة رد</Button>
                                </div>
                                <div className='mt-5'>
                                    {edit ? (
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div>
                                                <label>الرد</label>
                                                <textarea
                                                    {...register('answerText')}
                                                    placeholder='اكتب الرد على الاستشارة'
                                                    rows='5'
                                                    className='w-full border outline-none rounded-md border-gray-300 p-5'
                                                />
                                                {errors.answerText && <span className='text-red-500'>{errors.answerText.message}</span>}
                                            </div>
                                            <div className='my-3'>
                                                <Button type='primary' htmlType="submit">حفظ</Button>
                                                <Button type='danger' onClick={handleEdit}>إلغاء</Button>
                                            </div>
                                        </form>
                                    ) : (
                                        <p>{consult.answerText}</p>
                                    )}
                                </div>
                            </div>

                            {/* زر جديد لتحديد أو تعديل تاريخ الاجتماع */}
                            <Divider />
                            <div>
                                <Button type='dashed' onClick={() => setEditMeeting(!editMeeting)}>
                                    {consult?.meetingDate ? "تعديل تاريخ الاجتماع" : "تحديد تاريخ الاجتماع"}
                                </Button>
                                {editMeeting && (
                                    <div className='mt-3'>
                                        <Controller
                                            name="meetingDate"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    onChange={(date) => onSubmitMeetingDate(date)}
                                                    placeholder='اختر تاريخ الاجتماع'
                                                    className='w-full border outline-none rounded-md border-gray-300'
                                                    showTime
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
