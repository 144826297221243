import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const authApi = axios.create({
  baseURL: `https://doctor-api-cbmb.onrender.com/api/v1`,
  withCredentials: true,
});

export const login = createAsyncThunk(
  "auth/login",
  async (user, { rejectWithValue }) => {
    try {
      const response = await authApi.post('/auth/login', user);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to log in";
      return rejectWithValue(errorMessage);
    }
  }
);

// register user

export const userRegister = createAsyncThunk(
  "auth/userRegister",
  async (user, { rejectWithValue }) => {
    try {
      const response = await authApi.post('/auth/register', user);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to register";
      return rejectWithValue(errorMessage);
    }
  }
);

// logout user
export const logoutUser = createAsyncThunk('auth/logoutUser', async(_, { rejectWithValue }) => {
  try {
    const response = await authApi.get('auth/logout');
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Failed to log in";
    return rejectWithValue(errorMessage);
  }
}
);


// check-auth-role and set auth and role

export const checkUserAuth = createAsyncThunk('auth/checkUserAuth', async (_, { rejectWithValue }) => {
  try {
    const response = await authApi.get('/auth/check-auth-role');  
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Failed to authenticate";
    return rejectWithValue(errorMessage);
  }
}
);


const authSlice = createSlice({
  name: "auth",
  initialState: {
    user:null,
    isAuthenticated: false,
    role: null,
    status: "idle",
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.role = null;
      state.user = null;
    },
    setAuth: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.role = action.payload.role;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "success";
        state.isAuthenticated = true;
        state.user = action.payload.data.user;
        state.role = action.payload.data.user.role;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.isAuthenticated = false;
      });

      // register
      builder
     .addCase(userRegister.pending, (state) => {
       state.status = "loading";
       state.error = null;
     })
     .addCase(userRegister.fulfilled, (state, action) => {
       state.status = "success";
     })
     .addCase(userRegister.rejected, (state, action) => {
       state.status = "failed";
       state.error = action.payload;
       state.isAuthenticated = false;
     });

    // logout
    builder
     .addCase(logoutUser.pending, (state) => {
       state.status = "loading";
       state.error = null;
     })
     .addCase(logoutUser.fulfilled, (state) => {
       state.status = "success";
       state.isAuthenticated = false;
       state.role = null;
       state.user = null;


     })
     .addCase(logoutUser.rejected, (state, action) => {
       state.status = "failed";
       state.error = action.payload;
     });

    // check User Auth  
    builder
     .addCase(checkUserAuth.pending, (state) => {
       state.status = "loading";
       state.error = null;
     })
     .addCase(checkUserAuth.fulfilled, (state, action) => {
       state.status = "success";
       state.isAuthenticated = true;
       state.role = action.payload.role;
     })
     .addCase(checkUserAuth.rejected, (state, action) => {
       state.status = "failed";
       state.error = action.payload;
       state.isAuthenticated = false;
     });
  },
});

export const { logout, setAuth } = authSlice.actions;
export default authSlice.reducer;
