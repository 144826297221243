import { Table } from "antd";
import dayjs from "dayjs";
import React from "react";
import status from "../utils/status";
import { Link } from "react-router-dom";

export default function ReportTable({
  data,
  isloading,
  handleFetch,
  totalPages,
  currentPage
}) {
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "مقدم الطلب",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "نوع الطلب",
      dataIndex: "type",
      key: "type",
    },
    {
      title: " الملفات مرفقة",
      dataIndex: "files",
      key: "files",
    },
    {
      title: "حالة الطلب",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "تاريخ الطلب",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "عرض",
      key: "edit",
      dataIndex:"edit"
    }
  ];

  
  const page = currentPage;
  const dataSource = data?.map((el, index) => {
    return {
      key: el._id,
      id: (page - 1) * 10 + index + 1,
      name: el.createdBy?.userName,
      type: el.typeOfReport === "civil" ? "مدني" : "جنائي",
      files: el.files.length > 0 ? el.files.length : "لا ملفات مرفقة",
      status: status(el.statusOfReport),
      date: dayjs(el.createdAt).format("DD/MM/YYYY"),
      edit: <Link to={`/admin/details/${el._id}`} className="text-[13px]">عرض التفاصيل</Link>,
    };
  }) 

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          total: totalPages,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `Total ${total} items`,
          defaultPageSize: 10,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (page, pageSize) => {
            handleFetch({ page, limit: pageSize });
          },
         
        }}
        loading={isloading}
        scroll={{x:500}}
      />
    </div>
  );
}
