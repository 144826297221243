import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../features/userSlices";
import SearchComponent from "../../components/SearchComponent";
import UsersComponent from "../../components/UsersComponent";
import { TbReload } from "react-icons/tb";

export default function Users() {
  const { users, isLoading, isError, currentPage } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUsers({ currentPage }));
  }, [dispatch, currentPage]);

  const searchFun = ({ page, search }) => {
    dispatch(fetchUsers({ page, search }));
  };
  const handleFetch = ({ page, limit }) => {
    dispatch(fetchUsers({ page, limit }));
  };
  return (
    <>
      <div>
        <h3 className="title my-4">المستخدمين</h3>

        {isError && <>
          <div > 
          <h3>حدث خطأ ما, حاول مجدداً</h3>
            <button onClick={() => dispatch(fetchUsers({ currentPage }))} className="flex items-center gap-2 bg-main rounded-md p-2 text-white">
              إعادة التحميل  <TbReload />
            </button>
          </div>
        </>}

        <SearchComponent
          SearchFunction={searchFun}
          reload={handleFetch}
          page={currentPage}
        />

        <UsersComponent
          currentPage={currentPage}
          data={users}
          handleFetch={searchFun}
          isloading={isLoading}
        />
      </div>
    </>
  );
}
