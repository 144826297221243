import React, { useEffect, useState } from "react";
import UsersComponent from "../../components/UsersComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersStaff } from "../../features/userSlices";
import { Button, Modal } from "antd";
import NewUser from "./NewUser";

export default function Employees() {
  const { staffs, isLoading, isError, currentPage } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersStaff({ currentPage }));
  }, [dispatch, currentPage]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
       <div className="flex justify-between items-center">

         <h3 className="title my-4">الموظفين</h3>


          <div>
            <Button onClick={showModal} type="dashed">
              اضافة موظف
            </Button>
          </div>

        </div>

        {isError && <h3>حدث خطأ ما, حاول مجدداً</h3>}


        <UsersComponent
          currentPage={currentPage}
          data={staffs}
          isloading={isLoading}
        />
      </div>

      <Modal title='اضافة موظف' open={isModalOpen} footer={false} onCancel={handleCancel} className="w-full">
        <NewUser />
      </Modal>
    </>
  );
}
