import { Button, Layout, Menu } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { Archive, FilePen, LucideLayoutDashboard, MessageSquareText, User, Users } from 'lucide-react';
import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import AdminHeader from '../common/AdminHeader'; // التأكد من المسار الصحيح للمكون
import { LuAlignJustify } from 'react-icons/lu';

const items = [
  {
    key: '/admin',
    icon: <LucideLayoutDashboard size={22} />,
    label: <Link to='/admin' className='text-md'>الرئيسية</Link>,
  },
  {
    key: '/admin/cases',
    icon: <Archive size={22} />,
    label: "البلاغات",
    children: [
      {
        key: '/admin/criminal',
        label: <Link to='/admin/criminal'>القسم الجنائي</Link>,
      },
      {
        key: '/admin/civil',
        label: <Link to='/admin/civil'>القسم المدني</Link>,
      },
      {
        key: '/admin/all-reports',
        label: <Link to='/admin/all-reports'>كل البلاغات</Link>,
      },
    ],
  },
  {
    key: '/admin/employees',
    icon: <Users size={22} />,
    label: <Link to='/admin/employees' className='text-md'>الموظفين</Link>,
  },
  {
    key: '/admin/consulting',
    icon:<MessageSquareText size={22} />,
    label: <Link to='/admin/consulting' className='text-md'>الأستشارات</Link>,
  },
  {
    key: '/admin/users',
    icon: <User size={22} />,
    label: <Link to='/admin/users' className='text-md'>المستخدمين</Link>,
  },
];

export default function Admin() {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const location = useLocation();
  
  return (
    <div>
      <Layout className='h-screen'>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{ background: "#002979" }}
          className='z-20 absolute top-0 bottom-0 md:relative'
          width={260}
        >
          <div className="h-[64px] p-3 flex justify-start items-center gap-x-2">
           <div>
           <h2 className='font-semibold text-2xl text-white'>Elshargawi</h2>
           </div>
            <div className='md:hidden'>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{ marginBottom: 16 }}
          >
            <LuAlignJustify />
          </Button>
          </div>
          </div>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[location.pathname]}
            items={items}
            className='bg-transparent space-y-3'
            
          />

        </Sider>
        <Layout className='overflow-y-scroll'>
          <Header style={{ background: "#FFF" }}>
            <AdminHeader toggleCollapsed={toggleCollapsed} />
          </Header>
          <Content style={{ margin: '24px 16px 0' }}>
            <div style={{ padding: 24, minHeight: '100%' }}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
