import { createSlice ,createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
    users:[],
    user: null,
    staffs:[],
    isLoading: false,
    isError:null,
    totalPages: 0,
    currentPage: null,
    totalUsers: null
}

export const fetchUsersStaff = createAsyncThunk("users/fetchUsersStaff", async ({ page=1, search ,limit=10}, { rejectWithValue }) => {
    try {
        const params = new URLSearchParams({ page ,limit});
        if (search) {
            params.append('search', search);
        }
        const response = await api.get(`/users/staff?${params.toString()}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

//  fetch users

export const fetchUsers = createAsyncThunk("users/fetchUsers", async ({ page=1, search, limit=10}, { rejectWithValue }) => {
    try {
        const params = new URLSearchParams({ page, limit });
        if (search) {
            params.append('search', search);
        }
        const response = await api.get(`/users?${params.toString()}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

// delete user our staff

export const deleteUser = createAsyncThunk("users/deleteUser", async (id, { rejectWithValue }) => {
    try {
        await api.delete(`/users/${id}`)
        return { id }
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

// get my account data

export const fetchMyAccountData = createAsyncThunk("users/fetchMyAccountData", async (_, { rejectWithValue }) => {
    try {
        const response = await api.get(`/users/account`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

// update my account data

export const updateMyAccountData = createAsyncThunk(
    'user/updateMyAccountData',
    async ({ userId, data }, thunkAPI) => {
      try {
        const response = await api.put(`/users/${userId}`, data);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );
//   updatePassword

export const userUpdatePassword = createAsyncThunk(
    'user/userUpdatePassword',
    async (newData, thunkAPI) => {
        console.log(newData);
        
      try {
       
        const response = await api.put('/auth/change-password/', newData);
        return response.data; 
      } catch (error) {
        
        const errorMessage = error?.response?.data?.message || 'An error occurred';
        return thunkAPI.rejectWithValue(errorMessage); 
      }
    }
  );
const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers: (builder)=>{
        builder
        // fecth staffs
       .addCase(fetchUsersStaff.pending, (state) => {
            state.isLoading = true
            state.isError = null
           
        })
       .addCase(fetchUsersStaff.fulfilled, (state, action) => {
            state.isLoading = false
            state.staffs = action.payload.data.docs
            state.totalPages = action.payload.data.totalPages
            state.currentPage = action.payload.data.page
            state.totalUsers = action.payload.data.totalDocs
        })
       .addCase(fetchUsersStaff.rejected, (state, action) => {
            state.isLoading = false
            state.isError = action.payload
        })

        // fetch just users 
        builder
       .addCase(fetchUsers.pending, (state) => {
         state.isLoading = true
            state.isError = null
            state.users = null
       })
       .addCase(fetchUsers.fulfilled, (state, action) => {
            state.isLoading = false
            state.users = action.payload.data.users.docs
            state.totalPages = action.payload.data.users.totalPages
            state.currentPage = action.payload.data.users.page
            state.totalUsers = action.payload.data.users.totalDocs
       })
       .addCase(fetchUsers.rejected, (state, action) => {
            state.isLoading = false
            state.isError = action.payload
       })
    //    delete user
    builder 
    .addCase(deleteUser.pending, (state) => {
        state.isLoading = true
        state.isError = null
     })
     .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.users = state.users.filter(user => user._id!== action.payload.id)
        state.staffs = state.staffs.filter(staff => staff._id!== action.payload.id)
        
     })
     .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false
        state.isError = action.payload
     })

    // get my account data
    builder
    .addCase(fetchMyAccountData.pending, (state) => {
        state.isLoading = true
        state.isError = null
     })
     .addCase(fetchMyAccountData.fulfilled, (state, action) => {
        state.isLoading = false
        state.user = action.payload.data.user
     })
     .addCase(fetchMyAccountData.rejected, (state, action) => {
        state.isLoading = false
        state.isError = action.payload
     })  

    //  update My AccountData
    builder
    .addCase(updateMyAccountData.pending, (state) => {
        state.isLoading = true
        state.isError = null
     })
     .addCase(updateMyAccountData.fulfilled, (state, action) => {
        state.isLoading = false
        state.user = action.payload.data.user
     })
     .addCase(updateMyAccountData.rejected, (state, action) => {
        state.isLoading = false
        state.isError = action.payload
     })

    //  updaet password
    builder
    .addCase(userUpdatePassword.pending, (state) => {
        state.isLoading = true
        state.isError = null
     })
     .addCase(userUpdatePassword.fulfilled, (state,action) => {
        state.isLoading = false
        state.user = action.payload.data.updatedUser
     })
     .addCase(userUpdatePassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = action.payload
     })
    }
 
})


export default usersSlice.reducer;  