

export default function status(status) {
  switch (status) {
    case 'underReview':
      return <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
        <span className="ml-2 text-xs text-gray-500">تحت المراجعة</span>
      </div>;
    case 'reviewed':
      return <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-full bg-green-400"></div>
        <span className="ml-2 text-xs text-gray-500">تم التقييم</span>
      </div>;
    case 'answered':
      return <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-full bg-blue-400"></div>
        <span className="ml-2 text-xs text-gray-500">تم الإجابة</span>
      </div>;
    case 'canceled':
      return <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-full bg-gray-400"></div>
        <span className="ml-2 text-xs text-gray-500">تم الإلغاء</span>
      </div>;
    case 'approved':
      return <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-full bg-green-400"></div>
        <span className="ml-2 text-xs text-gray-500">تمت الموافقة </span>
      </div>;
    default:
      return 'تحت المراجعة';
  }
}
