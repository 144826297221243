import React from 'react'

export default function Unauthorized() {
  return (
    <div>
      <div className='flex justify-center items-center'>
         <h4 className="text-red-400 ">
            
            غير مصرح لك بالوصول الي هذه الصفحة
         </h4>
      </div>
    </div>
  )
}
