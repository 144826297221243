import { TbBrandTelegram, TbBrandWechat, TbBrandWhatsapp } from "react-icons/tb";
import { MdOutlineCamera } from "react-icons/md"; // إضافة أيقونة Comera (مثال افتراضي)
import { FaFacebookMessenger } from "react-icons/fa";

export default function socialMedia(platform, link) {
  const icons = {
    WhatsApp: <TbBrandWhatsapp size={20} />,
    Telegram: <TbBrandTelegram size={20} />,
    Messenger: <FaFacebookMessenger size={20} />,
    Comera: <MdOutlineCamera size={20} />,
    Imo: <TbBrandWechat  size={20} />,
  };

  switch (platform) {
    case 'WhatsApp':
      return <a href={`https://wa.me/${link}`} target="_blank" rel="noopener noreferrer">{icons.WhatsApp}</a>;
    case 'Telegram':
      return <a href={`https://t.me/${link}`} target="_blank" rel="noopener noreferrer">{icons.Telegram}</a>;
    case 'Messenger':
      return <a href={`https://m.me/${link}`} target="_blank" rel="noopener noreferrer">{icons.Messenger}</a>;
    case 'Comera':
      return <a href={`https://comera.com/${link}`} target="_blank" rel="noopener noreferrer">{icons.Comera}</a>; // تعديل الرابط حسب المنصة
    case 'Imo':
      return <a href={`https://imo.im/${link}`} target="_blank" rel="noopener noreferrer">{icons.Imo}</a>; // تعديل الرابط حسب المنصة
    default:
      return null;
  }
}
