import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
    status: "idle",
    error: null,
    Totalusers:0,
    totalReports:0,
    totalInquiries:0,

}

export const fetchDashboardData = createAsyncThunk("dashboard/fetchDashboardData", async (_,{rejectWithValue}) => {
    try {
        const response = await api.get(`/analytics`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data.message)
    }
})

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder)=>{
        builder
       .addCase(fetchDashboardData.pending, (state) => {
         state.status = "loading"
         state.error = null
       })
       .addCase(fetchDashboardData.fulfilled, (state, action) => {
         state.status = "success"
         state.Totalusers = action.payload.data.totalUsers
         state.totalReports = action.payload.data.totalReports
         state.totalInquiries = action.payload.data.totalInquiries
       })
       .addCase(fetchDashboardData.rejected, (state, action) => {
         state.status = "failed"
         state.error = action.payload
       });

    },
})



export default dashboardSlice.reducer;