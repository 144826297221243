import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useDispatch, useSelector } from "react-redux";
import { AddNewReport } from "../features/reportSlice";
import { toast } from "react-toastify";
import LoadingComponent from "./LoadingComponent";
import { Form, Input, Button, Upload, Select, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const schema = z.object({
  text: z.string().min(1, "الحقل مطلوب"),
  files: z.array(z.any()).nonempty("الملفات مطلوبة"),
  typeOfReport: z.string(),
});

export default function MyForm() {
  const {
    reports,
    status,
    error: isError,
  } = useSelector((state) => state.report);
  const [fileList, setFileList] = useState([]);
  const [textHistory, setTextHistory] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: { files: [] ,
      text: "",
      typeOfReport: "",
     },

  });

  const dispatch = useDispatch();

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
    setValue(
      "files",
      fileList.map((file) => file.originFileObj)
    );
  };

  const handleTextChange = (e) => {
    const currentText = e.target.value;

    if (typingTimeout) clearTimeout(typingTimeout);

    const timeout = setTimeout(() => {
      if (!textHistory.includes(currentText)) {
        setTextHistory((prevHistory) => prevHistory + " " + currentText);
      }
    }, 2000);

    setTypingTimeout(timeout);
  };

  const onSubmit = async (data) => {
    const dataForm = new FormData();
    dataForm.append("text", data.text);
    dataForm.append("typeOfReport", data.typeOfReport);
    fileList.forEach((file) => dataForm.append("files", file.originFileObj));
    dataForm.append("textHistory", JSON.stringify(textHistory));

    const res = await dispatch(AddNewReport(dataForm));
    if (res.meta.requestStatus === "fulfilled") {
      toast.success("تم إرسال بلاغ بنجاح");
      setTextHistory("");
      setValue("files", []);
    }
    if (res.meta.requestStatus === "rejected") {
      toast.error("حدث خطأ أثناء إرسال بلاغ");
    }
  };

  if (status === "loading") return <LoadingComponent />;

  return (
    <div className="container mx-auto my-10">
      <Title  level={2}>إضافة توكيل جديد</Title>

      {isError && <span className="text-red-500">{isError}</span>}

      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <Form.Item label="ملفات بلاغ" required>
          <Upload
            fileList={fileList}
            onChange={handleFileChange}
            beforeUpload={() => false}
            multiple
          >
            <Button icon={<UploadOutlined />}>رفع الملفات</Button>
          </Upload>
          {errors.files && (
            <p className="text-red-500">{errors.files.message}</p>
          )}
        </Form.Item>

        <Form.Item
          label="نص بلاغ"
          validateStatus={errors.text ? "error" : ""}
          help={errors.text?.message}
        >
          <TextArea
            rows={4}
            {...register("text")}
            onChange={(e) => {
            setValue('text', e.target.value); 
            handleTextChange(e); 
    }}
          />
        </Form.Item>

        <Form.Item
          label="نوع بلاغ"
          validateStatus={errors.typeOfReport ? "error" : ""}
          help={errors.typeOfReport?.message}
        >
          <Select
            defaultValue="" // تأكد من وجود قيمة افتراضية
            onChange={(value) => setValue("typeOfReport", value)} // هنا نضبط القيمة مباشرة في الـ form
            placeholder="اختر نوع بلاغ"
          >
            <Option value="criminal">جنائي</Option>
            <Option value="civil"> مدني</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            إرسال بلاغ
          </Button>
        </Form.Item>
      </Form>


    </div>
  );
}
