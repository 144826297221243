
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';
import NotFound from '../components/NotFound';

const ProtectedRoute = ({ allowedRoles, children }) => {

  const {isAuthenticated,  role ,status} = useSelector((state) => state.auth);
  

  if (status === "loading") {
    return <div><LoadingComponent /></div>;
  }


  if (!isAuthenticated || (allowedRoles?.length && !allowedRoles.includes(role))) {
    return <NotFound />;
  }

  return children;
};

export default ProtectedRoute;
