import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCriminalReports } from '../../features/reportSlice';
import ReportTable from '../../components/ReportTable';

export default function Criminal() {
    const { criminalRep, currentPage, totalReports,status } = useSelector((state) => state.report);

const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchCriminalReports({ currentPage }));
  }, [dispatch,currentPage]);

  const handleFetch = ({ page, limit }) => {
    dispatch(fetchCriminalReports({ page, limit }));
  };


  return (
    <>
      <h2 className="title">البلاغات الجنائية </h2>

      <div className="my-5">
        <ReportTable
          data={criminalRep}
          totalPages={totalReports}
          handleFetch={handleFetch}
          currentPage={currentPage}
          isloading={status==='loading'}
        />
      </div>
    </>
  );
}
