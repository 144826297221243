import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateMyAccountData } from '../features/userSlices';

const platforms = ['WhatsApp', 'Telegram', 'Messenger', 'Comera', 'Imo'];

// Schema validation for form inputs
const schema = z.object({
  socialMedia: z.array(
    z.object({
      platform: z.string(),
      link: z.string().optional(),
    })
  ).optional(),
});

export default function UpdateMedia({ socialMedia, userId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const filledSocialMedia = data.socialMedia?.filter((media) => media.link);
      if (!filledSocialMedia.length) {
        toast.error('يجب ادخال على الأقل محرك تواصل واحد');
        return;
      }

      const res = await dispatch(updateMyAccountData({ userId, data: { socialMedia: filledSocialMedia } }));
      if (res.meta.requestStatus === "fulfilled") {
        toast.success('تم تعديل البيانات بنجاح');
      } else {
        toast.error('حدث خطأ أثناء تعديل البيانات');
      }
    } catch (error) {
      toast.error('حدث خطأ أثناء تعديل البيانات');
      return;
    }

    handleCancel();
  };

  return (
    <>
      <div>
        {/* Replace outer button with a div or span */}
        <div onClick={showModal} >
        <Button type='primary'>
        تعديل بيانات التواصل
        </Button>
        </div>
      </div>

      <Modal
        title="تعديل بيانات التواصل"
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
        okText="تحديث"
        cancelText="الغاء"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {platforms.map((platform, index) => (
            <div key={index} style={{ marginBottom: '16px' }}>
              <input
                {...register(`socialMedia[${index}].platform`)}
                type="text"
                value={platform}
                readOnly
                className="border-none"
              />
              <input
                {...register(`socialMedia[${index}].link`)}
                type="text"
                placeholder="رقم التواصل"
                defaultValue={socialMedia?.find((el) => el.platform === platform)?.link || ''}
              />
              {errors.socialMedia?.[index]?.link && (
                <div className="error">{errors.socialMedia[index].link.message}</div>
              )}
            </div>
          ))}

          <button type="submit" className="bg-main p-2 text-white rounded">حفظ</button>
        </form>
      </Modal>
    </>
  );
}
